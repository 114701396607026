import React from 'react';

interface IProps {
    text: string;
    allowedRemoteHosts: string[];
}

export default function LogLine(props: IProps) {
    const isUrlAllowedHost = (url: string): boolean => {
        try {
            const urlObj = new URL(url);
            return props.allowedRemoteHosts.includes(urlObj.host);
        } catch (error) {
            return false;
        }
    };
    const urlRegex = /(https:\/\/[^\s"'`]+)/g;
    const parts = props.text.split(urlRegex);
    return (
        <pre className='p-0 m-0 bg-white text-gray-900 w-fit'>
            {parts.map((part, index) => {
                if (urlRegex.test(part) && isUrlAllowedHost(part)) {
                    return (
                        <a
                            key={index}
                            href={part}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-blue-500 underline'
                        >
                            {part}
                        </a>
                    );
                }
                return <span key={index}>{part}</span>;
            })}
        </pre>
    );
}
