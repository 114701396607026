import React from 'react';
import config from '../config';

export default function FooterContent() {
    return (
        <p className='text-xs text-gray-500'>
            <span title={config.buildInfo}>© BioLib 2025</span>
            {config.isEnterprise ? null : <>
                <span className='mx-2'>•</span>
                <a href='/legal/terms/' className='text-gray-500 hover:text-gray-200'>
                    Terms
                </a>
                <span className='mx-2'>•</span>
                <a href='/legal/privacy-policy/' className='text-gray-500 hover:text-gray-200'>
                    Privacy Policy
                </a>
            </>}
            <span className='mx-2'>•</span>
            <a href='/contact/' className='text-gray-500 hover:text-gray-200'>
                Support
            </a>
            <span className='mx-2'>•</span>
            <a href='/docs/' className='text-gray-500 hover:text-gray-200'>
                Docs
            </a>
        </p>
    );
}
