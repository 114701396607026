import { useEffect, useState } from 'react';
import { parseFasta } from './validation';
import { ISequenceInputValue } from '../types';

export function getInitialSequenceInputValue(prefilledInput?: string): ISequenceInputValue {
    const initialRows: Record<number, Record<number, string>> = {};
    
    for (let i = 0; i < 100; i += 1) {
        initialRows[i] = { 0: '', 1: '' };
    }
    
    if (prefilledInput) {
        try {
            if (prefilledInput.includes('>')) {
                const records = parseFasta(prefilledInput);
                
                for (let i = 0; i < records.length; i += 1) {
                    const record = records[i];
                    initialRows[i] = { 0: record.sequenceId, 1: record.sequence };
                }
            } else {
                initialRows[0] = { 0: '', 1: prefilledInput.trim() };
            }
        } catch (error) {
            console.error('Failed to parse prefilled input as FASTA. Got error: ', error);
            initialRows[0] = { 0: '', 1: prefilledInput };
        }
    }
    
    return {
        filename: null,
        rows: initialRows
    };
}

export function useScrollbarWidth(): number {
    const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);

    const measureScrollbarWidth = () => {
        const scrollDiv = document.createElement('div');
        scrollDiv.style.width = '100px';
        scrollDiv.style.height = '100px';
        scrollDiv.style.overflow = 'scroll';
        scrollDiv.style.position = 'absolute';
        scrollDiv.style.top = '-9999px';
        document.body.appendChild(scrollDiv);

        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        setScrollbarWidth(scrollbarWidth);

        document.body.removeChild(scrollDiv);
    };

    useEffect(() => {
        measureScrollbarWidth();

        const handleResize = () => {
            measureScrollbarWidth();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return scrollbarWidth;
}
