import { navigate } from 'gatsby';
import { IAppIdToFavoriteId } from '../state/apps/app.types';
import config from '../config';
import { AppState, IApp, ISlimApp } from '@biolibtech/biolib-js';

export * from './loadingState';
export * from './sagaUtilityFunctions';
export * from './ApiUtils';

export function getRandomId(length?: number): string {
    return Math.random()
        .toString(36)
        .substr(2, length || 4);
}

export function isAppFavorite(appIdToFavoriteIdDict: IAppIdToFavoriteId, appUuid: string): boolean {
    return appIdToFavoriteIdDict.hasOwnProperty(appUuid);
}

export function saveFileAs(fileName: string, fileType: string, data: Uint8Array | Blob): void {
    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    downloadAsFile(url, fileName);
    window.URL.revokeObjectURL(url);
}

export function downloadAsFile(url: string, filename: string): void {
    const a = document.createElement('a');
    document.body.append(a);
    a.hidden = true;
    a.referrerPolicy = 'noopener,noreferrer';
    a.href = url;
    a.download = filename;
    a.click();
    document.body.removeChild(a);
}

export function navigateWindow(path: string): void {
    if (typeof window !== 'undefined') {
        navigate(path);
    }
}

export function getDateWithTime1MinToMidnightZulu(date: Date): Date {
    // Since date.setUTCHours() does not work properly with summer time a new Date is created from an ISO string
    return new Date(`${date.toISOString().split('T')[0]}T23:59:00.000Z`);
}

export function normalizeName(name: string): string {
    return name.replace(/-/g, '_').toLowerCase();
}

export const sleep = (milliseconds: number) => new Promise(async (resolve) => setTimeout(resolve, milliseconds));

export function redirectIfNotExpectedSubdomain(expectedAccountHandleSubdomain: string) {
    if (!config.isDev && typeof window !== 'undefined') {
        const accountHandleFromSubdomain = location.hostname.split('.')[0];
        if (accountHandleFromSubdomain !== expectedAccountHandleSubdomain) {
            if (
                accountHandleFromSubdomain !== undefined &&
                accountHandleFromSubdomain !== 'biolib' &&
                accountHandleFromSubdomain !== 'staging'
            ) {
                window.location.href = `https://biolib.com/${accountHandleFromSubdomain}/`;
            } else {
                window.location.href = 'https://biolib.com';
            }
        }
    }
}

export function appendSlashIfNotEndsWithSlash(path: string): string {
    if (path.endsWith('/')) {
        return path;
    }
    return `${path}/`;
}

export function isoDateStringToLocalDateTimeString(isoDateString: string): string {
    if (!isoDateString) {
        return '-';
    }
    const date = new Date(isoDateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export function isUint8ArrayValidUtf8(uint8Array: Uint8Array): boolean {
    try {
        new TextDecoder('utf-8', { fatal: true }).decode(uint8Array);
        return true;
    } catch (error) {
        return false;
    }
}

export function byteSizeToHumanReadable(sizeInBytes: number): string {
    if (sizeInBytes < 1024) {
        return sizeInBytes + ' bytes';
    } else if (sizeInBytes < 1024 * 1024) {
        return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
        return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
}

export enum AppAccessState {
    discoverable = 'discoverable',
    unlisted = 'unlisted',
    private = 'private',
}

export function getAppAccessState(app: IApp | ISlimApp): AppAccessState {
    if (app.state === AppState.draft) {
        return AppAccessState.private;
    }
    if (app.is_unlisted) {
        return AppAccessState.unlisted;
    }
    return AppAccessState.discoverable;
}

export function formatLogMesssageWithTimestamp(message: string): string {
    const date = new Date();
    return `[${date.toLocaleDateString()} ${date.toLocaleTimeString()}] ${message}\n`;
}
