import React from 'react';
import clsx from 'clsx';
import appRunSlice from '../appRunSlice';
import ComputeTab from './tabs/ComputeTab';
import ErrorTab from './tabs/ErrorTab';
import InputTab from './tabs/InputTab';
import TermsTab from './tabs/TermsTab';
import { Card, Icon } from '@blueprintjs/core';
import { Col, Row } from '@components/common';
import { IJobWrapper, IOpenAppPayload, JobStatus } from '../types';

interface IProps {
    openAppPayloadAwaitingAcceptTerms: IOpenAppPayload | null;
    isOpenInModal: boolean;
    jobWrapper: IJobWrapper | null;
    fullHeight: boolean;

    handleAcceptTerms?: () => void;
    handleConfirmQuit?: () => void;
    handleMinimizeApp?: () => void;
    handleQuitApp?: () => void;
    runJob: typeof appRunSlice.actions.runJob;
}

export default class App extends React.Component<IProps, {}> {
    public render(): React.ReactNode {
        const { isOpenInModal, jobWrapper, openAppPayloadAwaitingAcceptTerms } = this.props;
        const app = jobWrapper ? jobWrapper.app : openAppPayloadAwaitingAcceptTerms?.app;
        if (!app) {
            return <p>Can not render application</p>;
        }

        return (
            <Card
                className={clsx({
                    'app-card-base': !isOpenInModal,
                    'app-card-overlay': isOpenInModal,
                })}
            >
                {!isOpenInModal ? null : (
                    <header className='card-header has-background-white-ter print:hidden'>
                        <Row className='is-vcentered is-mobile' style={{ width: '100%' }}>
                            <Col className='is-12 has-text-centered' style={{ marginRight: '-60px' }}>
                                <h5 className='subtitle'>{app.name}</h5>
                            </Col>
                            <Col className='is-2' style={{ width: '100px', zIndex: 2 }}>
                                <Icon
                                    className='window-action-minimize'
                                    icon='minus'
                                    onClick={this.props.handleMinimizeApp}
                                />
                                <Icon className='window-action-close' icon='cross' onClick={this.props.handleQuitApp} />
                            </Col>
                        </Row>
                    </header>
                )}
                <this.RenderTab />
                <div className='fade-bottom' />
            </Card>
        );
    }

    private RenderTab: React.FC = () => {
        const { handleAcceptTerms, handleConfirmQuit, jobWrapper, runJob } = this.props;

        if (!jobWrapper) {
            if (!handleAcceptTerms || !handleConfirmQuit) {
                return null;
            }
            return (
                <TermsTab
                    onAcceptTerms={handleAcceptTerms}
                    onQuitApp={handleConfirmQuit}
                    defaultViewIsSignIn={true}
                    requireExplicitContentForGuestMode={true}
                    guestModeDescription={null}
                />
            );
        }

        switch (jobWrapper.status) {
            case JobStatus.input:
                return (
                    <InputTab
                        appRemoteHosts={jobWrapper.job.remote_hosts_with_warning}
                        settings={jobWrapper.job.app_version.settings}
                        onSubmit={(settingValues) => {
                            window.scrollTo(0, 0);
                            runJob({
                                jobId: jobWrapper.job.public_id,
                                settingValues,
                            });
                        }}
                        autoSubmitOnceValid={jobWrapper.job.app_version.auto_run_once_validation_passes}
                    />
                );
            case JobStatus.computing:
                return <ComputeTab jobWrapper={jobWrapper} fullHeight={this.props.fullHeight} />;
            case JobStatus.error:
                return <ErrorTab jobWrapper={jobWrapper} onQuit={this.props.handleConfirmQuit} />;
            case JobStatus.finished:
                return null;
            default:
                return null;
        }
    };
}
