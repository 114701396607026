import React from 'react';
import { Spinner, Tag } from '@blueprintjs/core';

interface IProps {
    progress: number;
}

export default function ProgressTag(props: IProps) {
    const { progress } = props;

    let message: string | null = null;
    if (progress < 0.05) {
        message = 'Creating job';
    } else if (progress >= 0.05 && progress < 0.1) {
        message = 'Warming up a new server';
    } else if (progress >= 0.1 && progress < 0.25) {
        message = 'Initializing';
    } else if (progress >= 0.25 && progress < 0.7) {
        message = 'Pulling images';
    }

    if (message === null) {
        return null;
    }

    return (
        <Tag large={true} intent='primary' minimal={true} rightIcon={<Spinner size={15} />}>
            {message}
        </Tag>
    );
}
