import { FieldProps } from 'formik';
import React, { useRef, useState, MouseEvent, DragEvent, useEffect } from 'react';
import clsx from 'clsx';
import { saveFileListToFormikField, combineFileLists } from './utils';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { byteSizeToHumanReadable } from '@utils';
import { TrashIcon } from '@heroicons/react/24/outline';

interface IProps extends FieldProps {
    autoSubmitOnceValid: boolean;
    defaultValue?: string;
    allowMultipleFiles?: boolean;
}

export default function DragAndDropArea(props: IProps) {
    const { field, form, autoSubmitOnceValid, defaultValue, allowMultipleFiles = false } = props;
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const selectedFiles = field.value as FileList;

    useEffect(() => {
        if (autoSubmitOnceValid && form.isValid && selectedFiles && selectedFiles.length > 0) {
            form.submitForm();
        }
    }, [form.isValid, selectedFiles]);

    const handleFileSelection = (files: FileList | null) => {
        const existingFiles = field.value as FileList;
        const newFiles =
            allowMultipleFiles && existingFiles && existingFiles.length > 0 && files && files.length > 0
                ? combineFileLists([existingFiles, files])
                : files;
        saveFileListToFormikField(newFiles, field, form, allowMultipleFiles);
    };

    const handleClearFile = (event: MouseEvent) => {
        event.stopPropagation();
        form.setFieldValue(field.name, null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);

        if (!allowMultipleFiles && event.dataTransfer.files.length > 1) {
            setErrorMessage('Only one file is allowed. Please select or drop a single file.');

            setTimeout(() => {
                setErrorMessage(null);
            }, 5000);
            return;
        }

        setErrorMessage(null);
        handleFileSelection(event.dataTransfer.files);
    };

    const isFileAdditionEnabled = !selectedFiles || selectedFiles.length === 0 || allowMultipleFiles;

    return (
        <div
            className={clsx(
                'flex-grow flex flex-col border-2 border-dotted items-center rounded-md group p-3 min-h-24',
                isDragging ? 'border-emerald-500' : 'border-gray-300',
                { 'cursor-pointer': isFileAdditionEnabled },
            )}
            onDrop={handleDrop}
            onDragOver={(event) => {
                event.preventDefault();
                setIsDragging(true);
            }}
            onDragLeave={() => setIsDragging(false)}
            onClick={() => isFileAdditionEnabled && fileInputRef.current?.click()}
        >
            <input
                hidden={true}
                multiple={allowMultipleFiles}
                onChange={(event) => handleFileSelection(event.target.files)}
                ref={fileInputRef}
                type='file'
            />
            <div className='flex flex-col w-full relative'>
                {selectedFiles && selectedFiles.length > 0 && (
                    <button
                        onClick={handleClearFile}
                        title='Clear files'
                        className='absolute top-0 z-10 right-0 inline-flex px-3 items-center md:w-46 lg:w-32 rounded bg-white py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 flex justify-center disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
                    >
                        <TrashIcon className='h-4 w-4 mr-1' /> Clear File{selectedFiles.length > 1 ? 's' : ''}
                    </button>
                )}
                {isFileAdditionEnabled && (
                    <div className='text-center my-auto'>
                        <ArrowDownTrayIcon className='h-10 w-10 text-gray-500 group-hover:text-cyan-800 mx-auto' />
                        <p className='text-lg text-gray-500 group-hover:text-cyan-800 select-none my-auto pr-2'>
                            <b>Choose {allowMultipleFiles ? 'files' : 'a file'}</b> or drag{' '}
                            {allowMultipleFiles ? 'them' : 'it'} here
                        </p>
                    </div>
                )}
                {selectedFiles && selectedFiles.length > 0 ? (
                    <div className='flex flex-col h-fit w-full relative'>
                        {Array.from(selectedFiles).map((file, index) => (
                            <div key={`${file.name}-${index}`} className='flex flex-row space-x-2 items-center mb-2'>
                                <h2 className='text-base text-gray-800 m-0'>
                                    {file.name} - {byteSizeToHumanReadable(file.size)}
                                </h2>
                                <button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        const dataTransfer = new DataTransfer();
                                        Array.from(selectedFiles).forEach((f, i) => {
                                            if (i !== index) {
                                                dataTransfer.items.add(f);
                                            }
                                        });
                                        saveFileListToFormikField(dataTransfer.files, field, form, true);
                                        if (fileInputRef.current) {
                                            fileInputRef.current.value = '';
                                        }
                                    }}
                                    title='Remove this file'
                                    className='p-1 text-gray-500 rounded-md bg-gray-50 hover:bg-gray-100 hover:text-red-600 m-0'
                                >
                                    <XMarkIcon className='h-5 w-5' />
                                </button>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            <div>
                {defaultValue && (!selectedFiles || selectedFiles.length === 0) && (
                    <span className='text-gray-600'>
                        [Using default file: {defaultValue}. To change, select a new file.]
                    </span>
                )}
                {errorMessage && <div className='mt-2 text-red-600 text-sm font-medium'>{errorMessage}</div>}
            </div>
        </div>
    );
}
