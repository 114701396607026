import { Cell, CellProps } from '@blueprintjs/table';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

interface IProps extends CellProps {
    value: string;
    setValue: (newValue: string) => void;
    onFocus: () => void;
    isFocused?: boolean;
    placeholder?: string;
    readOnly?: boolean;
}

export default function TextAreaCell(props: IProps): React.ReactElement {
    const inputRef = useRef<HTMLTextAreaElement>();
    const [localState, setLocalState] = useState(props.value);

    useEffect(() => {
        if (props.isFocused) {
            inputRef.current.focus();
            props.onFocus();
        }
    }, [props.isFocused]);

    useEffect(() => {
        setLocalState(props.value);
    }, [props.value]);

    return (
        <Cell
            className={props.className}
            loading={props.loading}
            style={{ ...props.style, padding: 1, margin: 0 }}
            cellRef={props.cellRef}
        >
            <textarea
                ref={inputRef}
                className={clsx('text-gray-900', props.readOnly && 'bg-gray-100')}
                value={localState}
                spellCheck={false}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                style={{
                    border: 'none',
                    fontFamily: 'monospace',
                    fontKerning: 'none',
                    fontOpticalSizing: 'none',
                    fontSize: '12px',
                    height: props.style.height,
                    letterSpacing: '0px',
                    lineHeight: '20px',
                    margin: 0,
                    paddingBottom: '1px',
                    paddingLeft: '6px',
                    paddingRight: '0px',
                    paddingTop: '1px',
                    resize: 'none',
                    width: props.style.width,
                }}
                onChange={(event) => {
                    setLocalState(event.target.value);
                    props.setValue(event.target.value);
                }}
            />
        </Cell>
    );
}
