import BaseResource from './BaseResource';
import { ISignInResponse, ISsoEnterpriseAuthorizationResponse } from './Sso.types';

export default class Sso extends BaseResource {
    protected basePath = '/api/sso';

    public enterpriseGetAuthorizationUrl(
        data: { domain?: string; sso_connection_uuid?: string; },
    ): Promise<ISsoEnterpriseAuthorizationResponse> {
        return this.post<ISsoEnterpriseAuthorizationResponse>({ url: `/enterprise/authorization-url/`, data })
    }

    public gitHubSignIn(data: { code: string; are_terms_accepted?: boolean; }): Promise<ISignInResponse> {
        return new Promise<ISignInResponse>(async (resolve, reject) => {
            try {
                const signInResponse = await this.post<ISignInResponse>({ url: `/github/signin/`, data });
                this.httpClient.setRefreshToken(signInResponse.refresh_token);
                this.httpClient.setAccessToken(signInResponse.access_token);
                resolve(signInResponse);
            } catch (error) {
                reject(error);
            }
        });

    }
}
